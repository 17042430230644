<template>
  <v-dialog
    v-bind="$attrs"
    v-model="show"
    max-width="520px"
    overlay-color="defaultBg"
  >
    <v-card class="mainBg pa-20">
      <div class="d-flex flex-wrap align-center pb-4">
        <div class="accentColor--text headline pb-4 pr-8">{{ title }}</div>
        
        <div class="accentColor--text headline pb-4">{{ subTitle }}</div>
      </div>
      <div class="pb-10 pb-sm-18">
        <h3 class="defaultColor--text">{{ message }}</h3>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          class="subtitle-1 accentColor--text secondary mr-10"
          large
          depressed
          height="50"
          :loading="loading"
          type="submit"
          @click.prevent="makeAction"
        >
          Yes
        </v-btn>
        
        <v-btn
          class="subtitle-1 accentColor--text error px-10"
          large
          depressed
          height="50"
          @click="closeDialog"
        >No</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirm',
  inheritAttrs: false,
  props: {
    value:  {
      type: Boolean,
      default: false
    },
    loading:  {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Delete'
    },
    subTitle: {
      type: String,
      default: 'Item'
    },
    message: {
      type: String,
      default: 'Are you sure you want to delete this item?'
    },
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
  },
  methods: {
    makeAction() {
      this.$emit('successAction')
    },
    closeDialog() {
      this.show = false
      this.$emit('cancel')
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (!value) {
          this.closeDialog()
        }
      }
    }
  }
}
</script>
